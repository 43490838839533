import React, { useEffect } from "react";
import "./styles.css";
import {
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  Input,
  Grid,
  Paper,
  Button,
  Link
} from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import AdjustIcon from "@mui/icons-material/Adjust";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest
  })
}));

export default function App() {
  const [expanded, setExpanded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [resultSet, setResultSet] = React.useState();

  useEffect(() => {
    setLoading(true);
    fetch(
      `https://8b3kdsuglk.execute-api.us-east-1.amazonaws.com/production/location/search?keyword=$restaurant&address=Bangalore`
    )
      .then((result) => result.json())
      .then((data) => {
        setLoading(false);
        console.log(data.result);
        setResultSet(data.result);
      });
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const { register, handleSubmit } = useForm({
    shouldUseNativeValidation: true
  });
  const handleSearch = (data) => {
    setLoading(true);
    const searchAPI = fetch(
      `https://8b3kdsuglk.execute-api.us-east-1.amazonaws.com/production/location/search?keyword=${data.keyword}&address=${data.location}`
    )
      .then((result) => result.json())
      .then((data) => {
        setLoading(false);
        console.log(data.result);
        setResultSet(data.result);
      });
  };

  return (
    <>
      <div className="search_location">
        <form onSubmit={handleSubmit(handleSearch)}>
          <Grid
            container
            spacing={2}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <FormControl>
                <InputLabel htmlFor="my-input">Keyword</InputLabel>
                <Input
                  aria-describedby="my-helper-text"
                  name="keyword"
                  id="keyword"
                  {...register("keyword", {
                    required: "Please enter keyword."
                  })}
                  defaultValue="restaurant"
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <InputLabel htmlFor="my-input">Location</InputLabel>
                <Input
                  aria-describedby="my-helper-text"
                  name="location"
                  {...register("location", {
                    required: "Please enter Location."
                  })}
                  id="location"
                  defaultValue="Bangalore"
                />
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl>
                <Button type="submit" variant="contained">
                  SEARCH
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </div>
      <Grid
        container
        direction="row"
        spacing={2}
        mt={4}
        justifyContent="center"
        alignItems="center"
      >
        {loading ? (
          <Stack spacing={1}>
            <Skeleton variant="text" />
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="rectangular" width={210} height={118} />
          </Stack>
        ) : (
          resultSet?.map((item, index) => {
            return (
              <Grid item md={4}>
                <Card sx={{ width: 300 }}>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        {item?.title?.substring(0, 1)}
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="settings">
                        <MoreVertIcon />
                      </IconButton>
                    }
                    title={item?.title}
                    subheader={item?.rating}
                  />
                  <CardMedia
                    component="img"
                    height="194"
                    image={item.images[1]}
                    alt="Paella dish"
                  />
                  <CardContent>
                    <Typography variant="h5" color="text.secondary">
                      {item?.phone}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item?.address}
                    </Typography>
                  </CardContent>
                  <CardActions disableSpacing>
                    <IconButton aria-label="Direction">
                      <a
                        target="_blank"
                        href="https://maps.google.com/?cid=8532387538660556645"
                      >
                        <AddLocationAltIcon />
                      </a>
                    </IconButton>
                    {item?.open_now ? (
                      <IconButton aria-label="share">
                        <AdjustIcon /> Open
                      </IconButton>
                    ) : null}
                  </CardActions>
                </Card>
              </Grid>
            );
          })
        )}
      </Grid>
    </>
  );
}
